
.GdprBanner {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  background-color: yellow;
  color: black;
  z-index: 1000;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}

.GdprBanner .GdprButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.GdprBanner .Text {
  flex: 1 1;
  margin-right: 10px;
}

.GdprBanner .AcceptButton {
  padding: 5px 10px;
  cursor: pointer;
  background-color: red;
  color: white;
  text-align: center;
  margin-bottom: 10px;
}

.GdprBanner .DenyButton {
  cursor: pointer;
  text-align: center;
  padding: 0px 4px;
}

.MainPage {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.MainPageContent {
  max-width: 1000px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.FacebookWidget {
  background-color: #222222;
  padding: 8px;
  margin-left: 10px;
  max-width: 350px;
  max-height: 20px;
}

.RadiosContainer {
  flex: 1 1;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.RadioBox {
  flex: 1 1;
  margin: 2px;
  padding: 5px;
  height: 70px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

.RadioBox.Selected {
  /* border: 1px solid red; */
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: selected 0.3s;
          animation: selected 0.3s;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.RadioFavourite {
  position: absolute;
  right: 0;
  top: 0;
}

.RadioImage img {
  max-width: 100%;
  max-height: 100%;
}

.RadioImage {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.FakeRadioBox {
  padding: 0px 5px;
  margin: 0px 2px;
  height: 0;
  background-color: 'transparent';
}

.RadioName {
  flex: 0 1;
  min-height: 15px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Logo {
  width: 60px;
  height: 60px;
}

.LogoLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 40px;
}

.TermsAndConditionsCloseButton {
  color: yellow;
}

.LogoLink span {
  margin: 0px 16px 0px 2px;
}

.Header {
  flex: 0 1;
  width: 100%;
  max-width: 1000px;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index: 100;
}

.Header.Mobile {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  min-height: 70px;
  background-color: #222222;
}

.ErrorContainer {
  margin-top: 100px;
  background-color: #333;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorContainer .Error {
  margin: 20px 0px;
  font-size: 16px;
  color: white;
}

.ErrorContainer .TryAgain {
  color: white;
  background-color: #444;
  padding: 8px 16px;
  border-radius: 4px;
}

.Player .Spacer {
  width: 8px;
}

.Player img {
  max-width: 100%;
  max-height: 100%;
}

.PlayIcon {
  margin-left: 5px;
}

.FlexSpacer {
  flex: 1 1;
}

.RadiosContainer .Spacer {
  height: 70px;
  width: 100%;
}

.Footer {
  flex: 0 1;
  width: 100%;
  min-height: 78px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  justify-content: center;
}

.Footer .ContactPrivacyBar {
  width: 100%;
  text-align: center;
  background: #333333;
  padding-bottom: 8px;
}

.Footer .ContactPrivacyBar a {
  color: white;
  font-size: 10px;
  margin: 0px 10px;
  text-decoration: underline;
}

.Countries {
  display: flex;
  flex-direction: row;
}

.Footer .Countries .Country>* {
  -webkit-filter: brightness(0.6);
          filter: brightness(0.6);
}

.Footer .Countries .Country {
  background-color: #333333;
}

.Footer .Countries {
  background: #333333;
  padding: 0px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #222222;
}

.Country {
  /* background-color: #344767; */
  background-color: #222222;
  margin: 4px;
  padding: 4px;
  min-width: 60px;
  font-size: 10px;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  /* filter: brightness(0.8); */
  /* border-top: 2px solid transparent; */
}

.Footer .Countries .Country.Selected>* {
  -webkit-filter: brightness(1.1) !important;
          filter: brightness(1.1) !important;
}

.Country.Selected {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: selected 0.3s;
          animation: selected 0.3s;
  /* filter: brightness(1.1); */
  /* border-top-color: white; */
}

.Country>span {
  font-size: 20px !important;
  margin-bottom: 4px;
}

.MobileApps {
  height: 48px;
  margin-left: 20px;
  background: #222222;
}

.MobileApps>a {
  display: inline-block;
  height: 48px;
}

.AppBadge {
  width: 135px;
  height: 40px;
  margin: 4px;
}

.PageContent {
  display: flex;
  flex-direction: row;
}

.VerticalBanner {
  flex: 0 1;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  min-height: 300px;
  /* background-color: white; */
}

.Player {
  max-width: 100%;
  height: 60px;
  padding: 5px 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.PlayButton {
  border: 2px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PrevNextButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.PlayerButton {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border: 1px dashed transparent;
  cursor: pointer;
}

.PlayerSquareButton {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  /* border: 1px dashed transparent; */
  cursor: pointer;
}

.PlayerButton:hover {
  border-style: solid;
}

.SocialLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-right: 20px;
  margin-left: 20px;
}

a {
  text-decoration: none;
}

.TermsAndConditions .OptInOutContainer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid yellow;
}

.TermsAndConditions {
  color: white !important;
  background: #222222;
  padding: 10px;
}

.TermsAndConditions .OptInOut {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.TermsAndConditions .OptInOut p {
  font-weight: bold;
  padding: 0px 10px 0px 0px;
  margin: 4px 0px;
  min-width: 150px;
  color: yellow;
}

.TermsAndConditions .OptInOut span {
  padding: 0px 10px 0px 0px;
}

.TermsAndConditions .AcceptButton {
  padding: 5px 10px;
  cursor: pointer;
  background-color: red;
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 20px 0px 10px 0px;
}

.Link {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.LargeDesktop {
  display: none;
}

.Desktop {
  display: none;
}

.Mobile {
  display: none;
}

.Tablet {
  display: none;
}

.MenuButton {
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MenuButton a {
  color: yellow;
}

.DrawerMenu {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s ease;
  touch-action: none;
}

.DrawerMenu .RadioBox, .DrawerMenu .Country.Selected {
  -webkit-animation: none !important;
          animation: none !important;
}

.DrawerMenu .ContactPrivacyBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1;
  text-align: center;
  margin-bottom: 20px;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.DrawerMenu .ContactPrivacyBar a {
  color: white;
  text-decoration: underline;
  font-size: 12px;
  padding: 4px 0px;
}

.DrawerMenu .Countries {
  padding-left: 8px;
}

.DrawerMenu .DrawerMenuContainer {
  margin-left: -200px;
  width: 240px;
  /* height: 100%; */
  padding-top: 70px;
  padding-bottom: 70px;
  transition: margin-left 0.3s ease;
  touch-action: none;
}

.DrawerMenu.NoRadioSelected .DrawerMenuContainer {
  padding-bottom: 0px;
}

.DrawerMenuContent {
  background-color: #222222;
  width: 100%;
  height: 100%;
  pointer-events: all;
  display: flex;
  flex-direction: column;
}

.DrawerMenuContent .Countries {
  flex-direction: column;
  position: relative;
  flex: 1 1;
  overflow-y: auto;
  touch-action: none;
}

.DrawerMenuContent .FacebookWidget {
  margin-bottom: 20px;
  text-align: center;
  padding-right: 16px;
}

.DrawerMenuContent .CountriesLabel {
  color: white;
  padding-left: 16px;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
}

.DrawerMenuContent .Countries .Country {
  flex-direction: row;
  min-height: 0px;
  height: auto;
  font-size: 12px;
  align-items: center;
  background-color: transparent;
  border-bottom: none;
}

.DrawerMenuContent .Countries .Country span {
  margin-right: 8px;
  margin-bottom: 0px;
  font-size: 16px !important;
}

.DrawerMenu.Visible .DrawerMenuContainer {
  margin-left: 0px;
}

.DrawerMenu.Visible {
  opacity: 1;
}

.DrawerMenu.Hidden {
  pointer-events: none;
  opacity: 0;
}

.RadioPlayer {
  z-index: 100;
  touch-action: none;
}

.RadioPlayer:empty {
  visibility: hidden;
}

.AppTitle {
  color: white;
  font-size: 20px;
  padding-left: 20px;
  font-family: 'Courier New', Courier, monospace
}

.AdPlaceholder {
  width: 100%;
  min-width: calc(100% - 4px);
  max-width: calc(100% - 4px);
  flex: 1 1;
  margin: 2px;
  background-color: rgba(20, 20, 20, 0.5);
  background-image: url('/logo-bw.png');
  background-size: auto 80%;
  background-position: center;
  background-repeat: no-repeat;
}

.VerticalAd {
  min-width: 200px;
  max-width: 200px;
  margin-top: 102px;
}

.AdPlaceholder.Vertical {
  background-color: transparent;
  background-image: none;
}

.AdPlaceholder.Small {
  /* height: 50px;
  max-height: 50px; */
}

.AdPlaceholder.Medium {
  /* height: 60px; */
  /* max-height: 60px; */
}

.AdPlaceholder.Large {
  /* height: 90px; */
  /* min-height: 300px; */
  /* border: 2px solid red; */
}

/* .AdPlaceholder ins.adsbygoogle {
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  text-align: center;
} */

@media screen and (min-width: 1401px) {
  .LargeDesktop {
    display: inherit;
  }
}

@media screen and (min-width: 1001px) {
  .Desktop {
    display: inherit;
  }
  .RadioBox {
    min-width: 140px;
  }
  .RadioPlayer {
    position: absolute;
    right: 2px;
    top: 16px;
  }
  .PageContent {
    padding-bottom: 90px;
  }
  .TermsAndConditions {
    border: 1px solid yellow;
  }
}

@media screen and (min-width: 618px) and (max-width: 1000px) {
  .Tablet {
    display: inherit;
  }
  .RadioBox {
    min-width: 140px;
  }
  .RadioPlayer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    min-height: 70px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
  .RadioPlayer.ForSnapshot .Player {
    height: 140px;
  }
  .TermsAndConditions {
    margin-top: 70px;
  }
}

@media screen and (max-width: 618px) {
  .Mobile {
    display: inherit;
  }
  .RadioBox {
    min-width: 110px;
  }
  .RadioPlayer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    min-height: 70px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  }
  .RadioPlayer.ForSnapshot .Player {
    height: 120px;
    justify-content: center;
    align-items: center;
  }
  .RadioPlayer.ForSnapshot .Player .FlexSpacer {
    display: none;
  }
  .RadioPlayer.ForSnapshot .Player .SocialLinks {
    display: none;
  }
  .RadioPlayer.ForSnapshot .Player .PrevNextButtons {
    display: none !important;
  }
  .RadioPlayer.ForSnapshot .Player .PlayButton {
    display: none;
  }
  .TermsAndConditions {
    margin-top: 70px;
  }
}

@-webkit-keyframes selected {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  68%, 72% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes selected {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  30% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  68%, 72% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
html {
  width: 100%;
  min-height: 100%;

  background: url('/background-bw.png');
                                                                                            }

body {
  width: 100%;
  min-height: 100%;

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: #222222; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

